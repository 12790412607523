<!-- comment.vue -->
<template>
    <button @click="Modalopen"><img src="../../assets/coment.png" alt="" class="smallicon"><span>{{ commentCount }}</span></button>

    <div class="commentModal" :class="{ open: isCommentModalOpen }">
      <div class="commentModalBody">
        <div @click="Modalclose" class="backbtn"><img src="../../assets/backbtn.png" alt="" class="smallicon"></div>
        <div>
          <!-- <p>↓投稿内容↓</p> -->
          <p>{{ articleBody }}</p>
          <br>
          <!-- <p>↓コメント追加↓</p> -->
          <div class="comment--content">
            <textarea v-model="commentText" cols="30" rows="10" placeholder="コメント内容" class="comment--textarea"></textarea>
            <button @click="NewComment" class="postbtn">コメントする</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { db } from '../../main';
import { doc, getDoc, updateDoc, arrayUnion, collection, addDoc, getDocs, setDoc } from 'firebase/firestore';
import { auth } from '../../main';

export default {
  props: {
    id: String,
  },
  setup(props) {
    const currentUser = ref(null);
    const originid = ref(localStorage.getItem('originId') || '');
    const isCommentModalOpen = ref(false);
    const articleBody = ref('');
    const commentText = ref('');
    const commentCount = ref(0);
    let commentsCollectionRef = null;
    const generateRandomId = () => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let randomId = '';
      for (let i = 0; i < 20; i++) {
          randomId += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return randomId;
    };

    const commentsLength = async () => {
      if (!commentsCollectionRef) {
        //console.log('Firestore コレクションへの参照がありません。');
        return;
      }

      const commentsSnapshot = await getDocs(commentsCollectionRef);
      commentCount.value = commentsSnapshot.size;
    };

    const Modalopen = async () => {
      if (!commentsCollectionRef) {
        //console.log('Firestore コレクションへの参照がありません。');
        return;
      }

      const commentsSnapshot = await getDocs(commentsCollectionRef);
      commentCount.value = commentsSnapshot.size;

      isCommentModalOpen.value = true;
    };

    const Modalclose = () => {
      isCommentModalOpen.value = false;
    };

    const NewComment = async () => {
      if (commentText.value.trim() === '') {
        alert('コメントがありません。');
        Modalclose();
        return;
      }

      try {
        // ランダムなドキュメントIDを生成
        const randomDocId = generateRandomId();

        const articleId = props.id;
        const articleDocRef = doc(db, 'whispers', articleId);
        const commentsCollectionRef = collection(articleDocRef, 'comments');
        const newCommentDocRef = doc(commentsCollectionRef, randomDocId); // newCommentDocRef を初期化

        const userDocRef = doc(db, 'users', currentUser.value.uid);
        const userDocSnap = await getDoc(userDocRef);
        const originidValue = userDocSnap.data().originid;

        const myCommentsCollectionRef = collection(userDocRef, 'mycomments');
        const newMyCommentDocRef = doc(myCommentsCollectionRef, randomDocId);

        // 新しいコメントをサブコレクションに追加
        await setDoc(newCommentDocRef, {
            originid: originidValue,
            text: commentText.value,
            point: 0,
            postid: articleId,
        });
        await setDoc(newMyCommentDocRef, {
            postid: articleId,
        });

        // コメント数を更新
        commentCount.value += 1;

        // 合算した文字数を保存する
        const totalLength = commentText.value.length;
        const todayDate = new Date();
        const formattedDate = todayDate.toISOString().split('T')[0].replace(/-/g, '');

        const lengthLogRef = doc(db, 'users', currentUser.value.uid, 'myCommentlengthLog', formattedDate);
        const lengthLogDoc = await getDoc(lengthLogRef);

        if (lengthLogDoc.exists()) {
            // 既存のデータに加算する
            const existingData = lengthLogDoc.data();
            const updatedData = {
                ALLlength: (existingData.ALLlength || 0) + totalLength
            };
            await setDoc(lengthLogRef, updatedData, { merge: true });
        } else {
            // 新しいデータを作成する
            const charCountData = {
                ALLlength: totalLength
            };
            await setDoc(lengthLogRef, charCountData, { merge: true });
        }

        // コメントを追加した後、commentTextをクリアしてtextareaをリセット
        commentText.value = '';

        // コメントを追加したことを通知
        alert('コメントを追加しました！');
        Modalclose();
      } catch (error) {
        //console.log('コメントの追加中にエラーが発生しました:', error);
      }
    };

    onMounted(async () => {
        auth.onAuthStateChanged(async user => {
            currentUser.value = user;
        });

        // コンポーネントがマウントされたときに Firestore コレクションへの参照を作成
        const articleId = props.id;
        const articleDocRef = doc(db, 'whispers', articleId);
        commentsCollectionRef = collection(articleDocRef, 'comments');

        // 初回のコメント数を取得
        commentsLength();
    });

    return {
      currentUser,
      originid,
      isCommentModalOpen,
      articleBody,
      commentText,
      commentCount,
      commentsLength,
      Modalopen,
      Modalclose,
      NewComment,
      generateRandomId
    };
  },
};
</script>



<style lang="scss" scoped>
    button{
        display: flex;
        align-items: center;
        justify-content: center;
      .smallicon{
      // width: 30%;
        width: 16px;
      }
        
      span{
        padding-left: 6px;
      }
    }

    .commentModal {
        display: none;
    }

    .commentModal.open {
        display: block;
        position: absolute;
        z-index: 99999;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
        // background-color: #8080804F;
        background-color: #000000ad;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        @media (max-width: 599px) {
          font-size: 10px;
        }
        .commentModalBody{
          background:linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
          // border: 1px solid #fff;
          border-radius: 10px;
          width: 600px;
        }
    }
    .comment{
      &--content{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px;
      }
      &--textarea{
        width: 100%;
        background-color: #323232;
        padding: 5px;
        line-height: 1.5;
      }
    }
    .postbtn{
      margin-top: 10px;
      width: 115px;
      height: 45px;
      text-align: center;
      font-size: 18px;
      background: linear-gradient(180deg, #1903031A 3.32%, #323232 94.3%), #00A8A8;
      }
    .backbtn{
      position: relative;
      left: 92%;
      top: -4px;
      width: 30px;
      height: 0px;
      @media (max-width: 599px) {
        left: 89%;
        top: 5px;
        }
      img{
        cursor: pointer;
        width: 30px;
      }
    }

</style>