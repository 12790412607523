<!-- homeview.vue -->

<template>

  <item />

  <FooterNav />

  <lord />
</template>

<script>
import { auth } from '@/main';
import item from '@/components/mainComponents/item.vue';
import Auth from '@/components/auth.vue';
import Header from '@/components/mainComponents/Header.vue';
import FooterNav from '@/components/mainComponents/FooterNav.vue';
import lord from '@/components/mainComponents/load.vue'

export default {
  components: {
    item,
    Auth,
    Header,
    FooterNav,
    lord
  },
  data() {
    return {
      
    };
  },
};
</script>