// main.js
import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // Storageの初期化

export { app, auth, db, storage };

const vueApp = createApp(App); // Create Vue app instance

// auth状態を監視
onAuthStateChanged(auth, (user) => {
    if (user) {
        // ユーザーがサインインしている場合の処理
        localStorage.setItem('authlog', 'true'); // ユーザー情報をストアに保存する
    } else {
        // ユーザーがサインアウトしている場合の処理
        localStorage.setItem('authlog', 'false'); // ユーザー情報を削除する
    }
});

// 認証状態の持続性を設定
setPersistence(auth, browserSessionPersistence)
    .then(() => {
        // //console.log('セッション持続性が設定されました');
    })
    .catch((error) => {
        // //console.log('持続性設定中にエラーが発生しました:', error);
    });

vueApp.use(store).use(router).mount('#app'); 

