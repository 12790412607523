<!-- Header.vue -->
<template>
    <header>
        <router-link to="/">
        <h1></h1>
        </router-link>

        <!-- ここから追加 -->
        <div v-if="currentUser" class="btns">
            <router-link :to="'/user/'+currentUser.uid"> <!-- ここを追加 -->
        
                <button :style="'background-image: url('+currentUser.photoURL+')'">
                </button>
                
            </router-link> <!-- ここを追加 -->
            <router-link :to="'/login'" @click="signOut">
                <img src="@/assets/logout.png" alt="">
            </router-link>
        </div>
        <div v-else class="btns">
            <button @click="signIn">
                <img class="button-icon" src="@/assets/usericon.png">
            </button>
        </div>
        <!-- ここまで追加 -->
    </header>
</template>

<script>
import { signInWithPopup, signOut, GoogleAuthProvider, } from 'firebase/auth'; // 必要なモジュールをインポート
import { collection, doc, setDoc } from 'firebase/firestore';
import { db } from '../../main';
import { auth } from '../../main';

export default {
    data() {
        return {
        currentUser: null,
        };
    },

    created () {
        auth.onAuthStateChanged(user => {
            this.currentUser = user;
        });
    },

    methods: {
        signIn() {
            const provider = new GoogleAuthProvider(); // GoogleAuthProviderを直接使用
            signInWithPopup(auth, provider) // authオブジェクトを指定してsignInWithPopupを呼び出す
                .then((result) => {
                alert('Hello, ' + result.user.displayName + '!');

                //console.log('ユーザー情報:', result.user);
                this.$router.push('/user/'+result.user.uid) 
                this.createUser(result.user) 
                })
                .catch((error) => {
                //console.log('Sign-in error:', error);
            });
        },

        createUser(user) {
            const userRef = doc(db, 'users', user.uid);
            const userData = {
                name: user.displayName,
                photoURL: user.photoURL,
                email: user.email
            };
            
            setDoc(userRef, userData, { merge: true })
                .then(() => {
                    // 成功時の処理
                })
                .catch((error) => {
                    //console.log('Error creating user:', error);
                });
        },

        signOut() {
            if (window.confirm('ログアウトしますか？')) {
                signOut(auth)
                .then(() => {
                    // ローカルストレージをクリア
                    // localStorage.clear();
                    // alert('ログアウトしました');
                    this.$router.push('/login');
                })
                .catch((error) => {
                    //console.log('Sign-out error:', error);
                });
            }
        },
    },
};

</script>

<style scoped>

.button-icon{
    width: 20px;
    margin: 4px 0;
}

</style>

<style scoped lang="scss">
header {
    position: fixed;
    top: 0;
    right: 0;
    width: 10%;
    text-align: center;
    padding: 10px;
    height: 5rem;
    z-index: 99999999;
    h1 {
        width: fit-content;
        margin: 0 auto;
        font-size: 1.4rem;
    }

    .btns {
        position: absolute;
        top: 10px;
        right: 30px;
        cursor: pointer;

        img {
        width: 100%;
        }
    }
}

button {
    margin: 0 0 0 5px;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background: #555;
    background-size: cover;
    color: white;
    font-family:  "Inter",'Quicksand','YuGothic','Yu Gothic', serif;
    font-size: 1.2rem;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    &:focus {
        outline: none;
    }
}


</style>
