// src/store/index.js
import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      authlog: false,
      nowdate: '',
      ALLlist: JSON.parse(sessionStorage.getItem('ALLlist')) || [],
      sortedWhispers: JSON.parse(sessionStorage.getItem('sortedWhispers')) || [],
      serchWhispers: JSON.parse(sessionStorage.getItem('sortedWhispers')) || [],
      userMylist: JSON.parse(sessionStorage.getItem('userMylist')) || [],
      favoriteComment: JSON.parse(sessionStorage.getItem('favoriteComment')) || [],
    };
  },
  mutations: {
    setAuthLog(state, value) {
      state.authlog = value;
    },
    setNowDate(state, value) {
      state.nowdate = value;
    },
    ALLlist(state, ALLlist) {
      state.ALLlist = ALLlist;
      sessionStorage.setItem('ALLlist', JSON.stringify(ALLlist));
      //console.log('ソートされたALLlist:', state.ALLlist);
    },
    setSortedWhispers(state, sortedWhispers) {
      state.sortedWhispers = sortedWhispers;
      sessionStorage.setItem('sortedWhispers', JSON.stringify(sortedWhispers));
      //console.log('ソートされたsortedWhispers:', state.sortedWhispers);
    },
    addSortedWhispers(state, newWhispers) {
      // 既存の sortedWhispers に新しいデータを追加
      state.sortedWhispers = [...state.sortedWhispers, ...newWhispers];
      sessionStorage.setItem('sortedWhispers', JSON.stringify(state.sortedWhispers));
      //console.log('新しいWhispersがソートされたsortedWhispersに追加されました:', state.sortedWhispers);
    },


    setserchWhispers(state, sortedWhispers) {
      state.sortedWhispers = sortedWhispers;
      sessionStorage.setItem('sortedWhispers', JSON.stringify(sortedWhispers));
      //console.log('ソートされたsortedWhispers:', state.sortedWhispers);
    },
    setUserMylist(state, userMylist) { // 追加: セッションストレージに保存
      state.userMylist = userMylist;
      sessionStorage.setItem('userMylist', JSON.stringify(userMylist));
      //console.log('ユーザーのmylist:', state.userMylist);
    },
    setComment(state, favoriteComment) { // 追加: セッションストレージに保存
      state.favoriteComment = favoriteComment;
      sessionStorage.setItem('favoriteComment', JSON.stringify(favoriteComment));
      //console.log('ユーザーのmylist:', state.favoriteComment);
    },
    setSearchText(state, searchText) {
      state.searchText = searchText;
      sessionStorage.setItem('searchText', searchText);
    },
    clearSearchText(state) {
      state.searchText = '';
      sessionStorage.removeItem('searchText');
    },
  },
});

export default store;
