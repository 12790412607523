<!-- homeview.vue -->

<template>
    <item />

    <FooterNav />

    <lord />
</template>

<script>
    import { auth } from '@/main';
    import item from '@/components/noauthComp/item.vue';
    import Header from '@/components/mainComponents/Header.vue';
    import FooterNav from '@/components/noauthComp/FooterNav.vue';
    import lord from '@/components/mainComponents/load.vue'

    export default {
        components: {
            item,
            Header,
            FooterNav,
            lord
        },
        data() {
            return {
            };
        },
    };
</script>