<!-- Loginview.vue -->

<template>

    <Auth />

</template>

<script>
    import Auth from '@/components/login.vue';
    
    export default {
        components: {
            Auth,
        },
        data() {
            return {
                currentUser: null,
            };
        },
    };
</script>