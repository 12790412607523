<!-- footerNav.vue -->

<template>
    <!-- コメント投稿 -->
    <div v-if="isPostDetailPage" class="FooterCommentBar">
        <input type="text" placeholder="コメント追加" v-model="commentText">
        <button @click="addNewcomment">
            <img src="../../assets/comment.png" alt="コメント" class="smallicon">
        </button>
    </div>

    <nav class="footerNav">
        <!-- マイページボタン -->
        <button class="footerNav__mypage footer-w" @click="handleButtonClick">
            <div><img src="../../assets/myicon.png" alt="" class="smallicon">マイページ</div>
        </button>

        <!-- 課金遷移ボタン -->
        <button class="footerNav__billing footer-w"  @click="handleButtonClick">
            <div class="footerNav__billing">
                <!-- <p>999999999</p> -->
                <p> {{ userPoints || 0 }}</p>
                <img src="../../assets/LXmark.png" alt="" class="smallicon">
            </div>
        </button>

        <!-- 検索ボタン -->
        <div class="footerNav__search footer-w" @click="handleButtonClick">
            <!-- <span><img src="" alt=""></span> -->
            <p><img src="../../assets/search.png" alt="" class="smallicon search-img">検 索</p>
        </div>
        <!-- 投稿ボタン -->
        <div class="footerNav__postbtn" @click="handleButtonClick">
            <img src="../../assets/post.png" alt="">
        </div>
    </nav>

    <!-- 検索入力 -->
    <SerchModal :isOpen="isSearchModalOpen" @update:isOpen="handleButtonClick" />

    <PostModal :isPostModalOpen="isPostModalOpen" @update:isPostModalOpen="handleButtonClick" />
</template>

<script>
import { ref, onMounted } from 'vue';
import PostModal from "../FooterNavComp/postModal.vue";
import { useRoute, useRouter } from 'vue-router';
import { auth } from '../../main';
import { doc, getDoc, collection, addDoc, setDoc, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../main';
import SerchModal from "./SerchModal.vue";

export default {
    components: {
        PostModal,
        // ChargeModal,
        SerchModal
    },
    
    setup() {
        const route = useRoute();
        const router = useRouter();
        const currentUser = ref(null);
        const userPoints = ref(null);
        const isPostModalOpen = ref(false);
        const isSearchModalOpen = ref(false);
        const ischargeModalOpen = ref(false);
        const searchText = ref('');
        const isPostDetailPage = ref(route.name === 'postdetail');
        const commentText = ref(''); // コメントのテキストを保持するリアクティブ変数を追加

        onMounted(async () => {
            auth.onAuthStateChanged(async user => {
                currentUser.value = user;
                if (currentUser.value) {
                    await getUserPoints();
                }
            });
        });

        const generateRandomId = () => {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let randomId = '';
            for (let i = 0; i < 20; i++) {
                randomId += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return randomId;
        };
        const getUserPoints = async () => {
            try {
                const userDocRef = doc(db, 'users', currentUser.value.uid);
                const userDocSnapshot = await getDoc(userDocRef);

                if (userDocSnapshot.exists()) {
                    userPoints.value = userDocSnapshot.data().point;
                }
            } catch (error) {
                //console.log('ポイントの取得エラー:', error.message);
            }
        };

        const openPostModal = () => {
            if (currentUser.value) {
                isPostModalOpen.value = true;
            } else {
                //console.log("ユーザーがログインしていません。モーダルを開くことができません。");
            }
        };

        const updatePostModalState = (newState) => {
            isPostModalOpen.value = newState;
        };
        const updatechargeModalState = (newState) => {
            ischargeModalOpen.value = newState;
        };

        const searchmodal = () => {
            //console.log('おした');
            isSearchModalOpen.value = true;
        };

        const updateSearchModalState = (isOpen) => {
            // 検索モーダルの状態を更新
            isSearchModalOpen.value = isOpen;
        };

        const chargemodal = () => {
            //console.log('おした');
            ischargeModalOpen.value = true;
        };

        const addNewcomment = async () => {
            try {
                //console.log('currentUser.value:', currentUser.value);
                //console.log('commentText:', commentText.value.trim());

                // コメントテキストが空の場合、アラートを表示して処理を中断
                if (commentText.value.trim() === '') {
                    window.alert('テキストを入力してください');
                    return;
                }

                const articleId = route.params.id;
                const articleRef = doc(db, 'whispers', articleId);

                // 新しいドキュメントを作成し、ランダムなドキュメントIDを指定してコメントを追加する
                const commentsCollectionRef = collection(articleRef, 'comments');
                const newCommentDocRef = doc(commentsCollectionRef);

                // ドキュメントIDを取得
                const randomDocId = newCommentDocRef.id;
                // mycomments コレクションにも同じドキュメント名で追加する
                const userDocRef = doc(db, 'users', currentUser.value.uid);
                const userDocSnap = await getDoc(userDocRef);
                const originidValue = userDocSnap.data().originid;

                const myCommentsCollectionRef = collection(userDocRef, 'mycomments');
                const newMyCommentDocRef = doc(myCommentsCollectionRef, randomDocId);

                // コメントを追加する
                await setDoc(newCommentDocRef, {
                    originid: originidValue,
                    text: commentText.value,
                    point: 0,
                    postid: articleId,
                });

                await setDoc(newMyCommentDocRef, {
                    postid: articleId,
                });

                //console.log('コメントが追加されました。');
                commentText.value = ''; // コメントを追加した後、入力フィールドをクリア
                window.location.reload();
            } catch (error) {
                //console.log('コメントの追加中にエラーが発生しました:', error);
            }
        };


        const watchPayments = () => {
            const paymentsCollectionRef = collection(db, 'customers', auth.currentUser.uid, 'payments');
            const querySnapshot = query(paymentsCollectionRef);
            onSnapshot(querySnapshot, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    if (change.type === 'added') {
                        const payment = change.doc.data();
                        if (payment.status === 'succeeded') {
                            const amount = payment.amount;
                            updateUserPoint(amount);
                        }
                    }
                });
            });
        };

        // ユーザーのポイントを更新する
        const updateUserPoint = async (amount) => {
            try {
                const userDocRef = db.doc(`users/${auth.currentUser.uid}`); // .value.uid を追加
                const userDocSnapshot = await userDocRef.get();
                if (userDocSnapshot.exists()) {
                    const currentPoint = userDocSnapshot.data().point;
                    const updatedPoint = currentPoint + amount;
                    await userDocRef.update({ point: updatedPoint });
                    //console.log('ユーザーのポイントが更新されました。');
                }
            } catch (error) {
                //console.log('ユーザーのポイントの更新中にエラーが発生しました:', error);
            }
        };

        const handleButtonClick = () => {
            //console.log('おした');
            // ログインしているかどうかを確認
            if (!auth.currentUser) {
                // ログインしていない場合はアラートを表示してログインページに遷移
                if (confirm("ログインしてください")) {
                    router.push('/login');
                }
            } else {
                //console.log('なんかのエラ-');
                // ログインしている場合に実行したい処理をここに追加
            }
        };



        return {
            currentUser,
            userPoints,
            isPostModalOpen,
            isSearchModalOpen,
            ischargeModalOpen,
            searchText,
            commentText, // テキスト入力フィールドの内容を保持するリアクティブ変数を返す
            updateSearchModalState,
            openPostModal,
            updatePostModalState,
            updatechargeModalState,
            searchmodal,
            chargemodal,
            addNewcomment,
            route,
            isPostDetailPage,
            generateRandomId,
            handleButtonClick
        };
    }
};
</script>


<style lang="scss" scoped>

    .FooterCommentBar{
        width: 590px;
        height: 60px;
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translate(-50%,-0%);
        background:linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: #000;
        @media (max-width: 599px) {
            width: 100%;
            min-width: 320px;
            height: 60px;
        }
        input{
            border-radius: 20px;
            border: 0.5px solid #000;
            background: #D9D9D9;
            width: 90%;
            height: 40px;
            padding-left: 5px;
            margin-left: 10px;
        }
        img{
            height: 30px;
            margin: 0 10px;
        }
    }

    .footerNav{
        width: 590px;
        height: 60px;
        z-index: 2;
        min-width: 450px;
        @media (max-width: 599px) {
            width: 100%;
            min-width: 320px;
        }
        position: fixed;
        bottom: 0%;
        left: 50%;
        transform: translate(-50%,-0%);
        background:linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: #fff;
        &__postbtn{
            height: 60px;
            cursor: pointer;
            background:linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
            img{
                padding: 10px 15px;
                width: 100%;
                height: 100%;
                object-fit: contain;
                @media (max-width: 599px) {
                    padding: 5px 10px;
                }
            }
        }
        &__mypage{
            width: 50%;
            @media (max-width: 599px) {
                font-size: 12px;
                padding: 0 5px;
            }
            .smallicon{
                margin-right: 5px;
            }
        }
        &__billing{
            width: 90%;
            margin: 5px;
            background-color: #D9D9D9;
            border-radius: 30px;
            height: 38px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 599px) {
                padding: 0 5px;
            }
            @media (max-width: 380px) {
                margin: 0;
                padding: 0;
            }
            p{
                display: inline!important;
                padding-left: 15px;
                color: #323232;
                @media (max-width: 599px) {
                    padding-left: 5px;
                    font-size: 12px;
                }
            }
            img{
                height: 36px;
                width: 36px!important;
                margin-right: 2px!important;
                @media (max-width: 599px) {
                    margin-right: -3px !important;
                }
                @media (max-width: 380px) {
                    margin-right: 1px !important;
                }
            }
        }
        &__search{
            @media (max-width: 599px) {
                width: 25%!important;
            }
            @media (max-width: 390px) {
                padding: 0 8px 0 0;
            }
            .smallicon{
                margin-right: 5px;
                @media (max-width: 390px) {
                    margin-right: 0;
                }
            }
        }
        .footer-w{
            cursor: pointer;
            width: 30%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background:linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
            @media (max-width: 599px) {
                width: 38%;
            }
            p{
                display: contents;
                @media (max-width: 599px) {
                    font-size: 12px;
                }
            }
            .smallicon{
                width: 16px;
            }
            .search-img{
                width: 30px;
            }
            span{
                padding-left: 6px;
            }
        }
        .backbtn{
            position: relative;
            top: 5px;
            left: 90%;
            width: 16px;
            height: 5px;
            img{
                cursor: pointer;
                width: 16px;
            }
        }
    }
    .footerNav__search .footer-w {
        @media (max-width: 390px) {
            padding: 0;
        }
    }

    .disabled {
        cursor: not-allowed;
        pointer-events: none
    }
</style>