<!-- signup.vue -->

<template>
    <div class="auth">
        <div class="auth--box">
            <div class="auth--box__col col1">
                <img src="../assets/diposty.png" alt="" class="logo-img">
            </div>
            <div class="auth--box__col col2">
                <h1 class="col2--title">あなたの日記が、<br>だれかの旅になる。</h1>
                <p class="col2--catch">今すぐはじめよう。</p>

                <form @submit.prevent="createEmailAccount">
                    <input v-model="email" type="email" placeholder="メールアドレス" required class="btn--login__mailinput" autocomplete="email"/>
                    <div class="input-password">
                        <input v-model="password" :type="passwordFieldType" placeholder="パスワード" required class="btn--login__mailinput" autocomplete="current-password" ref="password"/>
                        <button type="button" class="toggle-password" @mousedown="showPassword('password')" aria-label="パスワードを表示">
                            <i :class="passwordFieldType === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                        </button>
                    </div>
                    <div class="input-password">
                        <input v-model="re_password" :type="re_passwordFieldType" placeholder="パスワードと同じ文字列を入力してください" required class="btn--login__mailinput" autocomplete="current-password"/>
                        <button type="button" class="toggle-password" @mousedown="showPassword('re_password')" aria-label="パスワードを表示">
                            <i :class="re_passwordFieldType === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                        </button>
                    </div>
                    <p v-if="emailError === 'unverified'" class="errorText">
                        このメールアドレスはメール認証が完了していません。認証メールを再送信する場合は
                        <button @click="sendEmail(unverifiedUser)" class="btn_emailError">こちら</button>
                        をクリックしてください。
                    </p>
                    <p v-else-if="emailError === 'already-registered'" class="errorText">
                        このメールアドレスは既に登録されています。<br>
                        <a href="/login" class="btn_emailError">こちら</a>
                        からログインしてください。
                    </p>
                    <p v-if="passwordError" class="errorText" v-html="passwordError"></p>
                    <div class="passText">
                        <p>パスワードは下記の条件を満たしてください。</p>
                        <p>・6文字以上</p>
                        <p>・1つ以上の大文字のアルファベットを含む</p>
                        <p>・1つ以上の数字を含む</p>
                    </div>

                    <button type="submit" class="btn--login__mailcreation" :class="{ disabled: isLoading }" :disabled="isLoading">
                        <img src="../assets/M.png" alt="" class="M-img">
                        メールアドレスで新規登録
                        <span v-if="isLoading" class="loading-overlay"><i class="fa fa-spinner fa-pulse fa-3x fa-fw loading-image"></i></span>
                    </button>
                </form>

                <div class="namekey">
                    <div>
                        <p class="namekey--en">Diary</p>
                        <p class="namekey--ja">日記</p>
                    </div>
                    <p class="namekey--en">＋</p>
                    <div>
                        <p class="namekey--en">Post</p>
                        <p class="namekey--ja">投稿</p>
                    </div>
                </div>
                <a href="/"><img src="../assets/iconorigin.png" alt="" class="logo-icon logoicon" ></a>

            </div>
        </div>
    </div>
</template>


<script>
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../main'; 

export default {
    data() {
        return {
            email: '',
            password: '',
            re_password: '',
            emailError: '',
            unverifiedUser: null,
            passwordError: '',
            passwordFieldType: 'password', // パスワードフィールドのタイプ
            re_passwordFieldType: 'password', // パスワードフィールドのタイプ
            showingPasswordField: null, // 現在表示中のパスワードフィールド
            isLoading: false,
        };
    },

    methods: {
        //パスワードの表示・非表示処理
        showPassword(field) {
            if (field === 'password') {
                this.passwordFieldType = 'text';
            } else if (field === 're_password') {
                this.re_passwordFieldType = 'text';
            }
            this.showingPasswordField = field;
            document.addEventListener('mouseup', this.handleMouseUp);
            document.addEventListener('touchend', this.handleMouseUp); 
        },
        handleMouseUp() {
            if (this.showingPasswordField === 'password') {
                this.passwordFieldType = 'password';
            } else if (this.showingPasswordField === 're_password') {
                this.re_passwordFieldType = 'password';
            }
            this.showingPasswordField = null;
            document.removeEventListener('mouseup', this.handleMouseUp);
            document.removeEventListener('touchend', this.handleMouseUp); 
        },

        async createEmailAccount() {
            // エラーメッセージをリセット
            this.emailError = '';
            this.passwordError = '';

            // バリデーションチェック
            if (this.password !== this.re_password) {
                this.passwordError = '※新しいパスワードと確認用の文字列は一致するように入力してください。';
                this.$refs.password.focus();
                return;
            }
            if (!this.validatePassword(this.password)) {
                this.$refs.password.focus();
                return;
            }

            const email = this.email;
            const password = this.password;

            this.isLoading = true;

            try {
                // Firebase Authenticationで新規アカウントを作成
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);

                // メール認証を送信
                await this.sendEmail(userCredential.user);

            } catch (e) {
                if (e.code === 'auth/email-already-in-use') {
                   // アカウントが既に存在する場合の処理
                   try {
                        const userCredential = await signInWithEmailAndPassword(auth, email, password);

                        // ユーザー情報をリロードして最新の状態に更新
                        await userCredential.user.reload();

                        // メール認証が完了しているか確認
                        if (!userCredential.user.emailVerified) {
                            // メール認証が未完了の場合、認証メールを再送信
                            this.unverifiedUser = userCredential.user;
                            this.emailError = 'unverified';
                            await auth.signOut();
                        } else {
                            // ログインページへのリンクを表示
                            this.emailError = 'already-registered';
                            await auth.signOut();
                        }
                    } catch (error) {
                        // ログインページへのリンクを表示
                        this.emailError = 'already-registered';
                    }
                } else {
                    alert('アカウント作成中にエラーが発生しました。');
                }
            } finally {
                this.isLoading = false;
            }
        },

        validatePassword(password) {
            const minLength = /.{6,}/;
            const uppercase = /[A-Z]/;
            const number = /[0-9]/;

            let errors = [];
            if (!minLength.test(password)) {
                errors.push('※パスワードは6文字以上で入力してください。');
            }
            if (!uppercase.test(password)) {
                errors.push('※パスワードには少なくとも1つの英語大文字を含める必要があります。');
            }
            if (!number.test(password)) {
                errors.push('※パスワードには少なくとも1つの数字を含める必要があります。');
            }

            if (errors.length > 0) {
                this.passwordError = errors.join('<br>'); // 全てのエラーを改行で繋げて表示
                return false;
            }
            return true;
        },

        async sendEmail(user) {
            const actionCodeSettings = {
                url: `${location.protocol}//${location.host}/auth`,
            };
            auth.languageCode = "ja";
            try {
                await sendEmailVerification(user, actionCodeSettings);
                alert("アカウント登録認証のためのメールを送りました。メールを確認してリンクへアクセスしてください。");
                this.$router.push('/login');
            } catch (e) {
                console.log(e);
                alert("アカウント作成中にエラーが発生しました。");
            }
        },
    },

    beforeUnmount() {
        // コンポーネントが破棄される前にイベントリスナーを削除
        document.removeEventListener('mouseup', this.handleMouseUp);
        document.removeEventListener('touchend', this.handleMouseUp);
    },
}
</script>

<style lang="scss" scoped>

    .auth{
        width: 100%;
        height: 100dvh;
        overflow: auto;
        padding: 20px 30px;
        min-width: 500px;
        @media screen and (max-width: 599px) {
            min-width: 370px;
        }
        @media screen and (max-width: 380px) {
            padding: 10px 30px;
            min-width: 320px;
        }
        form{
            width: 65%;
            min-width: 370px;
            @media screen and (max-width: 660px) {
                min-width: 330px;
            }
            @media screen and (max-width: 599px) {
                width: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                min-width: 310px;
            }
        }
        .logo-img{
            position: absolute;
            top :470px;
            z-index: -1;
            @media screen and (max-width: 880px) {
                top :600px;
            }
            @media screen and (max-width: 599px) {
                display: none;
            }
        }
        &--box{
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative; 
            @media screen and (max-width: 599px) {
                // flex-direction: column;
                flex-direction: column-reverse;
            }
            &__col{
                width: 50%;
                height: 100%;
                align-items: center;
                justify-content: center;
                @media screen and (max-width: 770px) {
                    width: 40%;
                }
                @media screen and (max-width: 599px) {
                    display: flex;
                    height: unset;
                    width: 100%;
                }
            }
            .col2{
                flex-direction: column;
                align-items: flex-start;
                color: white;
                @media screen and (max-width: 770px) {
                    width: 60%;
                }
                @media screen and (max-width: 599px) {
                    height: unset;
                    width: 100%;
                    align-items: center;
                }
                &--title{
                    font-size: 40px;
                    width: 100%;
                    margin-bottom: 15px;
                    min-width: 500px;
                    @media screen and (max-width: 599px) {
                        bottom: 12%;
                        padding-left: 17px;
                        letter-spacing: -1.5px;
                        line-height: 1.5;
                        min-width: 370px;
                    }
                    @media screen and (max-width: 380px) {
                        font-size: 35px;
                        min-width: 320px;
                    }
                }
                &--catch{
                    font-size: 25px;
                    margin: 25px 0;
                    @media screen and (max-width: 599px) {
                        position: relative;
                        padding-left: 17px;
                        width: 100%;
                    }
                    @media screen and (max-width: 380px) {
                        font-size: 20px;
                        padding-left: 0;
                    }
                }
            }
            .btn{
                &--login{
                    &__mailinput{
                        width: 100%;
                        min-width: 290px;
                        background-color: #D9D9D9;
                        color: #000;
                        text-align: start;
                        line-height: 45px;
                        border-radius: 30px;
                        margin-bottom: 15px;
                        padding-left: 15px;
                        @media screen and (max-width: 599px) {
                            height: 40px;
                        }
                    }
                    &__mailcreation{
                        width: 100%;
                        min-width: 290px;
                        background-color: #0B704B;
                        color: #ffffff;
                        text-align: center;
                        line-height: 45px;
                        border-radius: 30px;
                        margin-bottom: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        font-size: 12px;
                        .M-img{
                            width: 30px;
                            border-radius: 50%;
                            margin: 0 10px;
                            background: #ffffff;
                            padding: 3px;
                        }
                    }
                }
            }
            .btn--login__mailcreation.disabled {
                color: #b9b8b8;
                background-color: #696969;
                cursor: not-allowed;
            }
            .loading-overlay {
                display: flex;
                align-items: center;
                color: #00A8A8;
                font-size: 8px;
                margin-left: 5px;
            }
            .namekey{
                display: flex;
                padding-left: 10px;
                margin-top: 30px;
                @media screen and (max-width: 599px) {
                    top: 12%;
                    margin-left: auto;
                }
                @media screen and (max-width: 380px) {
                    padding-left: 0;
                    margin-left: 0;
                    text-align: center;
                }
                &--en{
                    font-size: 60px;
                    @media screen and (max-width: 380px) {
                        font-size: 50px;
                    }
                }
                &--ja{
                    font-size: 25px;
                    @media screen and (max-width: 380px) {
                        font-size: 20px;               
                    }
                }
            }
        }
        .logoicon{
            top: 16%;
            position: absolute;
            transform: translate(-50%, -50%);
            z-index: -1;
            opacity: 0.5;
            width: 220px;
            align-items: center;
            justify-content: center;
            @media screen and (min-width: 599px) {
                display: none;
            }
            @media screen and (max-width: 380px) {
                top: 15%;
                width: 190px;
            }
        }
        .input-password {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%; 
        }
        .input-password .btn--login__mailinput {
            padding-right: 40px; /* ボタンのスペースを確保 */
            width: 100%; 
        }
        .toggle-password {
            position: absolute;
            right: 20px;
            top: 45%; 
            transform: translateY(-50%);
            padding-bottom: 10px;
            background: none;
            border: none;
            cursor: pointer;
            color: dimgray;
        }
        .passText{
            text-align: left;
            margin-left: 20px;
            margin-bottom: 15px;
            @media screen and (max-width: 380px) {
                margin-left: 5px;
            }
        }
        .errorText{
            text-align: left;
            margin-left: 20px;
            margin-bottom: 15px;
            color: #e02f2f;
            .btn_emailError{
                color: #94b6ff;
            }
        }

        /* Edgeのパスワード表示ボタンを非表示 */
        input[type="password"]::-ms-reveal {
            display: none;
        }
        input[type="password"] {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
        }
    }
</style>