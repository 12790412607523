<!-- RequestResetPassword.vue -->

<template>
    <div class="RequestPass">
        <div class="Contents">
          <img src="../assets/icon.png" alt="" class="icon-img">
          <h2>パスワードの再設定</h2>
          <p>ご登録のメールアドレスを入力の上、「送信」をクリックしてください。</p>
          <p>「パスワード再設定ページURL」を記載したメールをお送りいたします。</p>
          <form @submit.prevent="sendResetEmail">
            <input v-model="email" type="email" placeholder="メールアドレス" class="input-email" autocomplete="email">
            <div>
              <button class="btn-send" :class="{ disabled: isLoading }" :disabled="isLoading"><span v-if="isLoading" class="loading-overlay"><i class="fa fa-spinner fa-pulse fa-3x fa-fw loading-image"></i></span>送信</button>
            </div>
            <p v-if="transLogin" class="transText">
                <a href="/login" class="trans_login">ログインページ</a>
                に移動
            </p>
          </form>
          <p v-if="message">{{ message }}</p>
        </div>
    </div>
</template>
  
<script>
  import { getAuth, sendPasswordResetEmail } from "firebase/auth";

  export default {
    data() {
      return {
        email: '',
        message: '',
        isLoading: false,
        transLogin: false,
      };
    },
    methods: {
      async sendResetEmail() {
        if (!this.email) {
          alert('メールアドレスを入力してください。');
          return;
        }

        this.isLoading = true;
        const auth = getAuth();

        try {
          await sendPasswordResetEmail(auth, this.email);
          alert('パスワード再設定用のメールを送信しました。メールをご確認ください。');
          this.transLogin = true;
        } catch (error) {
          if (error.code === 'auth/invalid-email') {
            alert('有効なメールアドレスを入力してください。');
          } else {
            alert('メールの送信に失敗しました。再度お試しください。');
          }
        } finally {
          this.isLoading = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
    .RequestPass {
      width: 100%;
      height: 100dvh;
      text-align: center;
      .Contents {
        margin: 60px auto;
        color: #fff;
        min-width: 320px;
        h2 {
          font-size: 25px;
          margin: 20px;
        }
        p {
          font-size: 15px;
        }
        .input-email{
          width: 80%;
          max-width: 450px;
          min-width: 320px;
          margin: 20px auto;
          background-color: #D9D9D9;
          color: #000;
          text-align: start;
          line-height: 45px;
          border-radius: 30px;
          padding-left: 15px;;
          @media screen and (max-width: 599px) {
              height: 40px;
          }
        }
        .btn-send {
          width: 130px;
          background-color: #0B704B;
          color: #ffffff;
          text-align: center;
          line-height: 45px;
          border-radius: 30px;
          margin: 0 auto;
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .btn-send.disabled {
          color: #b9b8b8;
          background-color: #696969;
          cursor: not-allowed;
        }
        .loading-overlay {
          display: flex;
          align-items: center;
          color: #00A8A8;
          font-size: 8px;
          margin-right: 5px;
        }
        .transText {
          margin-top: 20px;
          font-size: 18px;
          color: #ffffff;
          .trans_login {
            color: #94b6ff;
          }
        }
      }
    }
</style>