<!-- LoginBonusModal.vue -->

<template>
  <div v-if="showModal" class="LBmodal">
    <div class="LBmodal-content">
      <div>{{ modalTitle }}</div>
      <p>{{ bonusPoints }}ルクスが付与されました！</p>
      <button class="btn_LBmodal" @click="closeModal">OK</button>
    </div>
  </div>
</template>

<script>
import { auth, db } from '../main';
import { doc, getDoc, setDoc, updateDoc, Timestamp, increment } from 'firebase/firestore';

export default {
  data() {
    return {
      showModal: false,
      bonusPoints: 0,
      modalTitle: '',
      currentUser: null,
    };
  },

  methods: {
    async openModal() {
      // ユーザー情報の取得
      this.currentUser = auth.currentUser;
      if (!this.currentUser) {
        // ユーザーが未ログインの場合
        return;
      }

      const uid = this.currentUser.uid;
      const now = new Date();
      const todayAt5AM = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        5,
        0,
        0
      );

      if (now.getTime() < todayAt5AM.getTime()) {
        // 現在時刻が午前5時より前の場合、前日の午前5時を取得
        todayAt5AM.setDate(todayAt5AM.getDate() - 1);
      }

      const loginBonusDocRef = doc(db, 'users', uid, 'loginBonusLog', 'loginBonusData');
      const userRef = doc(db, 'users', uid);

      try {
        const loginBonusDocSnap = await getDoc(loginBonusDocRef);

        if (!loginBonusDocSnap.exists()) {
          // 初回ログイン
          this.bonusPoints = 3000;
          this.modalTitle = '初回限定ログインボーナス';

          // ドキュメントを作成
          await setDoc(loginBonusDocRef, {
            loginStreak: 1,
            login: 1,
            firstLoginDate: Timestamp.fromDate(now),
            lastLoginDate: Timestamp.fromDate(now),
          });
        } else {
          const loginBonusData = loginBonusDocSnap.data();
          const lastLoginTimestamp = loginBonusData.lastLoginDate;

          if (!lastLoginTimestamp) {
            // 最終ログイン時間が取得できない場合
            await updateDoc(loginBonusDocRef, {
              lastLoginDate: Timestamp.fromDate(now),
            });
            return; // エラー処理
          }

          // lastLoginDateをDateオブジェクトに変換
          const lastLoginDate = lastLoginTimestamp.toDate();

          if (lastLoginDate < todayAt5AM) {
            // 最終ログインが当日の朝5時以前
            this.bonusPoints = 300;
            this.modalTitle = 'ログインボーナス';

            // ログイン情報を更新
            await updateDoc(loginBonusDocRef, {
              loginStreak: (loginBonusData.loginStreak || 0) + 1,
              login: (loginBonusData.login || 0) + 1,
              lastLoginDate: Timestamp.fromDate(now),
            });
          } else {
            // 当日5時以降にログイン済み
            this.bonusPoints = 0;
            return; // モーダルを表示しない
          }
        }

        // ユーザーのポイントを更新
        await updateDoc(userRef, {
          point: increment(this.bonusPoints),
        });

        // モーダルを表示
        this.showModal = true;
      } catch (error) {
        return;
      }
    },

    closeModal() {
      this.showModal = false;
      setTimeout(() => {
        window.location.reload();
      }, 300);
    },
  },
};
</script>
  
<style lang="scss" scoped>
  // ログインボーナスモーダル　背景
  .LBmodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
  }
  
  // ログインボーナスモーダル部
  .LBmodal-content {
    background-color: #323232;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #00a8a8;
    text-align: center;
    z-index: 40;

    div{
      font-size: 25px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    p{
      font-size: 18px;
      font-weight: 500;
    }
  }

  .btn_LBmodal {
    margin: 20px 8px 0 8px;
    border: none;
    border-radius: 5px;
    width: 100px;
    height: 35px;
    font-size: 16px;
    background: linear-gradient(180deg, #1903031A 3.32%, #4d4d4d 94.3%), #00A8A8;
  }
</style>