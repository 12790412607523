<template>
    <div v-if="isOpen" class="search-modal">
        <div class="search-modal--box">
            <button @click="closeModal">
                <img src="../../assets/backbtn.png" alt="" class="smallicon">
            </button>
            <p class="search-ttl">検索するタグを入力</p>
            <!-- <input v-model="searchText" type="text" class="text"> -->

            <button @click.prevent="newSearchtagInput" class="tagadd">
                検索タグを追加
            </button>

            <div class="scrollbox">

            </div>

            <!-- 新しいinputを追加する部分 -->
            <div v-for="(tag, index) in searchText" :key="index" class="taginput">
                <input v-model="searchText[index]" type="text" class="text">
                <button @click="removeSearchtagInput(index)" class="tagrem">
                    タグを削除
                </button>
            </div>

            <form action="#">
                <label for="checkbox"></label>
                <input type="checkbox" id="checkbox" name="checkbox" v-model="searchOptions.date">
                <span>指定した日付で検索</span><br>

                <div v-if="searchOptions.date" class="searchdate">
                    <datepicker v-model="selectedDate" class="time"></datepicker>
                    <p class="datepick">{{ formattedDate }}</p>
                </div>

                <input type="checkbox" id="checkbox" name="checkbox" v-model="searchOptions.myPosts">
                <span>自分の投稿した中から検索</span><br>

                <label for="checkbox2"></label>
                <input type="checkbox" id="checkbox2" name="checkbox2" v-model="searchOptions.withLux">
                <span>ルクスを追加した投稿から検索</span><br>

                <label for="checkbox3"></label>
                <input type="checkbox" id="checkbox3" name="checkbox3" v-model="searchOptions.favorite">
                <span>お気に入りポストから検索</span><br>

                <label for="checkbox4"></label>
                <input type="checkbox" id="checkbox4" name="checkbox4" v-model="searchOptions.disableBlock">
                <span>ブロックワードを無効にして検索</span><br>
            </form>

            <router-link :to="generateSearchRoute" @click="search" class="searchbtn">
                <img src="../../assets/search-w.png" alt="" class="searchbtn-img">
                    検 索
            </router-link>
        </div>
    </div>
</template>


<script>
import { ref, computed, watch  } from 'vue';
import { useRouter } from 'vue-router';
import { format } from 'date-fns';
import Datepicker from 'vue3-datepicker';

export default {
    components: {
        Datepicker
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true
        }
    },
    setup(props, { emit }) {
        const router = useRouter();
        const route = useRouter();
        // 日付関係の値
        const valueFormat = 'yyyy-MM-dd';
        const selectedDate = ref(new Date());
        // 入力テキストの値
        const searchText = ref(['サンプルタグ']); // 初期値をサンプルタグに設定
        // 検索オプション
        const searchOptions = ref({
            date: false,
            myPosts: false,
            withLux: false,
            favorite: false,
            disableBlock: false
        });

        // フォーマット関数の定義
        const formattedDate = computed(() => {
            return format(selectedDate.value, 'yyyy/MM/dd');
        });

        // selectedDateが変更されたらコンソールに表示
        watch(selectedDate, (newDate) => {
            //console.log("フォーマットした", format(newDate, 'yyyy/MM/dd'));
        });

        // タグ検索窓追加
        const newSearchtagInput = () => {
            if (Array.isArray(searchText.value)) {
                searchText.value.push('');
            } else {
                searchText.value = ['サンプルタグ'];
            }
            //console.log("searchTextの内容", searchText.value);
        };

        // タグ検索窓削除
        const removeSearchtagInput = (index) => {
            if (index !== 0 && Array.isArray(searchText.value)) {
                searchText.value.splice(index, 1);
            }
        };

        const closeModal = () => {
            // 親コンポーネントにイベントを発火し、isOpenプロパティを更新する
            emit('update:isOpen', false);
        };

        const search = () => {
            const searchParams = [];
            if (searchOptions.value.date) {
                searchParams.push(format(selectedDate.value, valueFormat));
            }
            if (searchOptions.value.myPosts) {
                searchParams.push('myPosts');
            }
            if (searchOptions.value.withLux) {
                searchParams.push('withLux');
            }
            if (searchOptions.value.favorite) {
                searchParams.push('favorite');
            }
            if (searchOptions.value.disableBlock) {
                searchParams.push('disableBlock');
            }

            //console.log("searchTextの内容", searchText.value);

            // ローカルストレージにsearchParamsを保存する
            localStorage.setItem('searchParams', JSON.stringify(searchParams));

            let searchTextParams = searchText.value.filter(tag => tag.trim() !== ''); // 空の値を除外
            let serchtextIF = searchText.value.filter(tag => tag.trim() !== '');
            // searchTextが配列であることを確認
            if (Array.isArray(searchText.value))  {
                //console.log("searchTextのleng", serchtextIF.length);
                // ローカルストレージからWordPrisetを取得して追加
                const wordPrisetFromStorage = localStorage.getItem('WordPriset');
                if (wordPrisetFromStorage) {
                    const wordPrisetArray = JSON.parse(wordPrisetFromStorage);
                    searchTextParams = searchTextParams.concat(wordPrisetArray);
                }

                if (serchtextIF.length == 0) {
                    //console.log("searchTextの内容2");
                    // 空の場合はlocalStorageから削除
                    localStorage.removeItem('searchTextParams');
                } else {
                    //console.log("searchTextの内容1");
                    localStorage.removeItem('searchTextParams');
                    localStorage.setItem('searchTextParams', JSON.stringify(searchTextParams));
                }
            } else {
                localStorage.removeItem('searchTextParams');
                //console.log("searchText.value is not an array");
            }

            emit('update:isOpen', false);

            // Vue Routerから現在のパスを取得
            const currentPath = router.currentRoute.value.path;
            // もし現在のパスが/notextsearchでlocalStorageにsearchTextParamsの値がない場合、ページをリロード
            const searchTextParamsFromStorage = localStorage.getItem('searchTextParams');
            if (currentPath === '/notextsearch' && !searchTextParamsFromStorage) {
                searchText.value = [''];
                location.reload();
            } else if (currentPath === '/notextsearch' && searchTextParamsFromStorage) {
                searchText.value = [''];
            }
        };

        // 検索ルートの生成
        const generateSearchRoute = computed(() => {
            // searchText の値が空の場合、または空文字列のみの場合、空のパスを返す
            if (searchText.value.length === 0 || searchText.value.every(tag => tag === '')) {
                emit('update:isOpen', false);
                return '/notextsearch';
            }
            return '/search/' + searchText.value;
        });

        return {
            searchText,
            selectedDate,
            valueFormat,
            closeModal,
            search,
            generateSearchRoute,
            searchOptions,
            newSearchtagInput,
            removeSearchtagInput,
            formattedDate
        };
    }
};
</script>







<style lang="scss" scoped>

    input[type="checkbox"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid #000;
        width: 20px;
        height: 20px;
        cursor: pointer;
        background: #D9D9D9;
        color: #10AE33;
        margin: 5px 0;
        margin-right: 15px;
    }

    /* チェックボックスがチェックされたときのスタイル */
    input[type="checkbox"]:checked::before {
        content: "\2713"; /* チェックマークのUnicode */
        display: block;
        text-align: center;
        background: #10AE33;
        color: #D9D9D9;
        font-weight: bold;
        line-height: 18px; /* チェックボックスの高さと同じ */
    }

    .search-ttl{
        font-size: 24px;
        font-weight: 600;
    }

    .searchdate{
        height: 50px;
    }

    .taginput{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 4px;
        width: 100%;
    }
    .tagrem{
        background: #C1C1C1;
        color: #323232;
        font-size: 15px;
        padding: 5px 15px;
        border-radius: 50px;
        font-weight: 600;
        margin-bottom: 4px;
        width: unset!important;
    }
    .tagadd{
        background: #C1C1C1;
        color: #323232;
        font-size: 15px;
        padding: 5px 15px;
        border-radius: 50px;
        font-weight: 600;
        width: unset!important;
        margin: 5px 0;
    }
    .datepick{
        transform: translate(-2px,-37px);
        background: #D9D9D9;
        color: #323232;
        pointer-events: none;
        width: 60%;
        padding: 5px 10px;
        border-radius: 5px;
    }

    .time{
        height: 30px;
    }


    input[type="text"] {
        font-size: 20px;
        background: #D9D9D9;
        color: #323232;
        line-height: 1.5;
        padding: 5px;
    }

/* スタイルをここに追加 */
    .search-modal {
        /* 適切なスタイルを追加 */
        display: block;
        position: absolute;
        z-index: 99999;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
        // background-color: #8080804F;
        background-color: #000000ad;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        &--box{
            width: 517px;
            @media (max-width: 599px) {
                width: 90%;
                min-width: 300px;
            }
            max-width: 517px;
            min-width: 517px;
            height: 500px;
            background:linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
            color: #D9D9D9;
            padding: 20px;
            border-radius: 2rem;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            gap: 12px;
            input.text{
                width: 100%;
                background: #D9D9D9;
                color: #323232;
                line-height: 1.5;
                padding: 5px;
                border: 1px solid black;
            }
            input.time{
                font-size: 20px;
                background: #D9D9D9;
                color: #47464600;
                line-height: 1.5;
                padding: 5px;
                outline: none;
            }
        }
        button{
            width: 100%;
            text-align: end;
        }
        .searchbtn{
            width: 107px;
            height: 37px;
            display: block;
            background: linear-gradient(180deg, #1903031A 3.32%, #323232 94.3%), #00A8A8;
            text-align: center;
            line-height: 2;
            margin: 10px auto 0px;
            font-size: 16px;
            &-img{
                width: 28%;
            }
        }
    }
    .v3dp__datepicker{
        height: auto;
        // background:linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
        width: 60%;
        background: #d9d9d900;
        color: #32323200;
        line-height: 1.5;
        padding: 5px;
        input.time {
            width: 100%;
        }       
    }
</style>
  
