<!-- auth.vue -->
<template>
    <div class="auth">
        <div class="auth--box">
            <div class="auth--box__col col1">
                <img src="../assets/diposty.png" alt="" class="logo-img">
            </div>
            <div class="auth--box__col col2">
                <h1 class="col2--title">あなたの日記が、<br>だれかの旅になる。</h1>
                <p class="col2--catch">今すぐはじめよう。</p>
                <div class="signUp">
                    <button @click="goToAbout" class="btn--login__signUp">
                        <img src="../assets/M.png" alt="" class="M-img">
                        メールアドレスで新規登録
                    </button>
                </div>
                <form @submit.prevent="createEmailAccount">
                    <input v-model="email" type="email" placeholder="メールアドレス" required class="btn--login__mailinput" autocomplete="email"/>
                    <div class="input-password">
                        <input v-model="password" :type="passwordFieldType" placeholder="パスワード" required class="btn--login__mailinput" autocomplete="current-password"/>
                        <button type="button" class="toggle-password" @mousedown="togglePassword()" @mouseup="togglePassword()">
                            <i :class="passwordFieldType === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                        </button>
                    </div>
                    <p v-if="passwordError" class="passErrorText" v-html="passwordError"></p>
                    
                    <button type="submit" class="btn--login__mailcreation">
                        <img src="../assets/M.png" alt="" class="M-img">
                        メールアドレスでログイン
                    </button>
                </form>

                <div class="separatorArea">
                    <div class="separator">
                        <hr><span>または</span><hr>
                    </div>
                </div>
                

                <div class="signUp">
                    <button @click="signIn" class="btn--login__creation">
                        <img src="../assets/G.png" alt="" class="G-img">
                        Googleアカウントで新規登録 / ログイン
                    </button>
                </div>

                <div class="namekey">
                    <div>
                        <p class="namekey--en">Diary</p>
                        <p class="namekey--ja">日記</p>
                    </div>
                    <p class="namekey--en">＋</p>
                    <div>
                        <p class="namekey--en">Post</p>
                        <p class="namekey--ja">投稿</p>
                    </div>
                </div>

                <div class="transaktionlink">
                    <a href="/transaction">特定商取引法に基づく表記</a>
                </div>

                <!-- <img src="../assets/icon.png" alt="" class="icon-img"> -->
                <a href="/"><img src="../assets/iconorigin.png" alt="" class="logo-icon logoicon" ></a>

            </div>
        </div>
    </div>
</template>


<script>
import { signInWithPopup, signOut, GoogleAuthProvider, signInWithEmailAndPassword } from 'firebase/auth'; // 必要なモジュールをインポート
import { collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../main';
import { auth } from '../main';
import { id } from 'date-fns/locale';

// ランダムな英数字20文字の生成関数
function generateRandomId(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }

    return result;
}

export default {
    props: ['currentUser'],

    data() {
        return {
            email: '',
            password: '',
            passwordError: '',
            error: "",
            passwordFieldType: 'password' // パスワードフィールドのタイプ
        };
    },

    methods: {
        togglePassword() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';        
        },

        goToAbout() {
            this.$router.push('/signup');
        },

        async signIn() {
            console.log('auth:', auth);
            const provider = new GoogleAuthProvider();
            try {
                const result = await signInWithPopup(auth, provider);

                if (!result.user.emailVerified) {
                    await auth.signOut();
                    alert('メール認証が完了していません。認証メールを確認してください。');
                    return;
                }

                // Firestoreのusersコレクション内にデータがないか確認
                const userRef = doc(db, 'users', result.user.uid);
                const docSnapshot = await getDoc(userRef);
                if (!docSnapshot.exists()) {
                    // ドキュメントが存在しない場合のみcreateUserを呼び出す
                    this.createUser(result.user);
                } else {
                    // 既にデータが存在する場合の処理
                    localStorage.setItem('authlog', 'true');
                    // ホームに遷移
                    this.$router.push('/');
                }
            } catch (error) {
               // console.log('サインインエラー:', error);
            }
        },

        async createUser(user) {
            const userRef = doc(db, 'users', user.uid);
            const randomId = generateRandomId(20);
            const todayDate = new Date().toISOString().split('T')[0]; // 今日の日付をYYYY-MM-DD形式で取得

            const userData = {
                name: user.displayName,
                photoURL: user.photoURL,
                email: user.email,
                originid: randomId,
                point: 0,
                lastLogin: todayDate,
                password: this.password,
            };

            try {
                await setDoc(userRef, userData, { merge: true });
                // ホームに遷移
                this.$router.push('/');
                localStorage.setItem('authlog', 'true');
            } catch (error) {
                // //console.log('ユーザー作成エラー:', error);
            }
        },

        async createEmailAccount() {
            //バリデーションチェック
            if (!this.validatePassword()) {
                return;
            }

            const email = this.email;
            const password = this.password;

            try {
                const result = await signInWithEmailAndPassword(auth, email, password);
                if (!result.user.emailVerified) {
                    await auth.signOut();
                    alert('メール認証が完了していません。認証メールを確認してください。');
                    return;
                }
                alert('ログイン成功');
                // //console.log('ユーザー情報:', result.user);

                // Firestoreのusersコレクション内にデータがないか確認
                const userRef = doc(db, 'users', result.user.uid);
                const docSnapshot = await getDoc(userRef);
                if (!docSnapshot.exists()) {
                    // ドキュメントが存在しない場合のみcreateUserを呼び出す
                    this.createUser(result.user);
                    return;
                } else {
                    // 既にデータが存在する場合の処理
                    //console.log('ユーザーデータは既に存在します:', docSnapshot.data());
                    localStorage.setItem('authlog', 'true');
                    // ホームに遷移
                    this.$router.push('/');
                }
            } catch (e) {
                //console.log("なんかのエラー", e.message);
                if (e.code === 'auth/invalid-login-credentials') {
                    alert("パスワードかメールアドレスが違います");
                    return;
                } else if (e.code === 'auth/too-many-requests') {
                    alert("しばらく時間を置いてからログインを試行してください");
                    return;
                } else {
                    alert("エラーが発生しました");
                    return;
                }
            }
        },

        validatePassword() {
            const minLength = /.{6,}/;
            const uppercase = /[A-Z]/;
            const number = /[0-9]/;

            let errors = [];
            if (!minLength.test(this.password)) {
                errors.push('※パスワードは6文字以上で入力してください。');
            }
            if (!uppercase.test(this.password)) {
                errors.push('※パスワードには少なくとも1つの英語大文字を含める必要があります。');
            }
            if (!number.test(this.password)) {
                errors.push('※パスワードには少なくとも1つの数字を含める必要があります。');
            }

            if (errors.length > 0) {
                this.passwordError = errors.join('<br>'); // 全てのエラーを改行で繋げて表示
                return false;
            }
            return true;
        },
    },
}
</script>




<style lang="scss" scoped>

.separatorArea {
    width: 65%;
    @media screen and (max-width: 599px) {
        width: 100%;
    }
}
.separator {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    min-width: 280px;
    padding: 0 10px;
    color: #FFF;
    margin: 10px 0;
    @media screen and (max-width: 599px) {
        padding: 0 15px;
    }
}
.separator hr {
    flex-grow: 1;
    border: none;
    border-top: 1px solid #FFF;
}
.separator span {
    white-space: nowrap;
    margin: 0 10px;
}

    .auth{
        width: 100%;
        height: 100dvh;
        overflow: auto;
        padding: 20px 30px;
        min-width: 500px;
        @media screen and (max-width: 599px) {
            min-width: 370px;
        }
        form{
            width: 65%;
            min-width: 300px;
            @media screen and (max-width: 599px) {
                width: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        .signUp{
            width: 65%;
            min-width: 300px;
            @media screen and (max-width: 599px) {
                width: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        .logo-img{
            @media screen and (max-width: 599px) {
                display: none;
            }
        }
        .icon-img{
            display: none;
            @media screen and (max-width: 599px) {
                display: block;
                position: absolute;
                top: 3%;
                z-index: -1;
                width: 70%;
                opacity: 0.3;
            }
        }
        &--box{
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 599px) {
                // flex-direction: column;
                flex-direction: column-reverse;
            }
            &__col{
                width: 50%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                @media screen and (max-width: 599px) {
                    height: unset;
                    width: 100%;
                }
            }
            .col2{
                flex-direction: column;
                align-items: flex-start;
                color: white;
                @media screen and (max-width: 599px) {
                    height: unset;
                    width: 100%;
                    align-items: center;
                }
                &--title{
                    font-size: 40px;
                    width: 100%;
                    margin-bottom: 15px;
                    min-width: 500px;
                    @media screen and (max-width: 599px) {
                        bottom: 12%;
                        padding-left: 17px;
                        letter-spacing: -1.5px;
                        line-height: 1.5;
                        min-width: 370px;
                    }
                }
                &--catch{
                    font-size: 25px;
                    margin: 25px 0;
                    @media screen and (max-width: 599px) {
                        position: relative;
                        padding-left: 17px;
                        width: 100%;
                    }
                }
            }
            .btn{
                &--login{
                    &__creation{
                        width: 100%;
                        min-width: 290px;
                        background-color: #0B704B;
                        color: #ffffff;
                        text-align: center;
                        line-height: 45px;
                        border-radius: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        font-size: 12px;
                        margin-top: 15px;
                        .G-img{
                            width: 30px;
                            border-radius: 50%;
                            margin: 0 10px;
                        }
                    }
                    &__mailinput{
                        width: 100%;
                        min-width: 290px;
                        background-color: #D9D9D9;
                        color: #000;
                        text-align: start;
                        line-height: 45px;
                        border-radius: 30px;
                        margin-bottom: 15px;
                        padding-left: 15px;
                        @media screen and (max-width: 599px) {
                            height: 40px;
                        }
                    }
                    &__mailcreation{
                        width: 100%;
                        min-width: 290px;
                        background-color: #0B704B;
                        color: #ffffff;
                        text-align: center;
                        line-height: 45px;
                        border-radius: 30px;
                        margin-bottom: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        font-size: 12px;
                        .M-img{
                            width: 30px;
                            border-radius: 50%;
                            margin: 0 10px;
                            background: #ffffff;
                            padding: 3px;
                        }
                    }
                    &__signUp{
                        width: 100%;
                        min-width: 290px;
                        height: 60px;
                        background-color: #13972f;
                        color: #ffffff;
                        text-align: center;
                        line-height: 45px;
                        border-radius: 30px;
                        margin-bottom: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        font-size: 18px;
                        font-weight: bold;
                        .M-img{
                            width: 40px;
                            border-radius: 50%;
                            margin: 0 10px;
                            background: #ffffff;
                            padding: 3px;
                        }
                    }
                }
            }
            .namekey{
                display: flex;
                margin-top: 30px;
                width: 100%;
                justify-content: center;
                @media screen and (max-width: 599px) {
                    top: 12%;
                }    
                &--en{
                    font-size: 60px;
                    @media screen and (max-width: 599px) {
                    }
                }
                &--ja{
                    font-size: 25px;
                    @media screen and (max-width: 599px) {                    
                    }
                }
            }
        }
        .logoicon{
            top: 15%;
            position: absolute;
            transform: translate(-50%, -50%);
            z-index: -1;
            opacity: 0.5;
            width: 220px;
            align-items: center;
            justify-content: center;
            @media screen and (min-width: 599px) {
                display: none;
            }
        }
        .input-password {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;

            .btn--login__mailinput {
                padding-right: 40px; /* ボタンのスペースを確保 */
                width: 100%; 
            }
        }
        .toggle-password {
            position: absolute;
            right: 20px;
            top: 45%; 
            transform: translateY(-50%);
            padding-bottom: 10px;
            background: none;
            border: none;
            cursor: pointer;
            color: dimgray;
        }
        .passErrorText{
            text-align: left;
            margin-left: 20px;
            margin-bottom: 15px;
            color: #e02f2f;
        }

        /* Edgeのパスワード表示ボタンを非表示 */
        input[type="password"]::-ms-reveal {
            display: none;
        }
        input[type="password"] {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
        }
        .transaktionlink{
            text-align: right;
            margin-top: 40px;
            width: 100%;
            min-width: 300px;
            @media screen and (max-width: 599px) {
                margin-top: 25px;
                text-align: left;
            }
        }
        .transaktionlink a {
            float: right;
        }
    }
</style>