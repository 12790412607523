<!-- TripButton.vue -->
<template>
    <button @click="tripDate">
        <img src="../../assets/trip-btn.png" alt="" class="trip-btn">
    </button>
</template>

<script>
    import { ref } from 'vue';
    import { db } from '../../main';
    import { getDocs, collection } from 'firebase/firestore';

    export default {
        methods: {
        async tripDate() {
            //console.log('押したトリップ')
            const whispersCollection = collection(db, 'whispers');
            const allWhispersSnapshot = await getDocs(whispersCollection);
            const randomWhisper = allWhispersSnapshot.docs[Math.floor(Math.random() * allWhispersSnapshot.docs.length)].data();
            if (randomWhisper && randomWhisper.setdays) {
            this.$emit('updateDate', randomWhisper.setdays);
            } else {
            //console.log("選択したドキュメントにおいて、setdaysフィールドが無効です。");
            }
        },
        },
    };
</script>

<style lang="scss" scoped>

.trip-btn{
    width: 30px;
    @media (max-width: 599px) {
        top: 0vh;
        position: relative;
    }
}
</style>
